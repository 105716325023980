import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import { IAnswer } from "./Chat";

interface Props {
  answer?: IAnswer;
}

function ChatResult(props: Props) {
  return (
    <Card>
      <Card.Header>Source Data</Card.Header>
      <ListGroup
        variant="flush"
        style={{ height: "80vh", overflowY: "scroll" }}
      >
        {props.answer?.evidence.map((evidence, index) => {
          return <ListGroup.Item key={index}>{evidence}</ListGroup.Item>;
        })}
      </ListGroup>
    </Card>
  );
}

export default ChatResult;
