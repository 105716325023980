import React from "react";
import { Button, Card, ListGroup, Spinner } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { IChat } from "./Chat";
import { useWebSocketContext } from "../WebsocketProvider";

interface ChatMessageProps {
  author: string;
  message: string;
  user: boolean;
  loading: boolean;
}

interface Props {
  chat?: IChat;
  setChatQuestion: (question: string) => void;
}

function ChatMessage(props: ChatMessageProps) {
  let className;
  if (props.user) {
    className = "ms-3 mt-1 text-end";
  } else {
    className = "me-3 mt-1";
  }
  return (
    <Card className={className}>
      <Card.Body>
        <Card.Title>{props.author}</Card.Title>
        {props.loading ? (
          <>
            <Spinner animation="grow" size="sm" />
            <Spinner animation="grow" size="sm" className="mx-2" />
            <Spinner animation="grow" size="sm" />
          </>
        ) : (
          props.message
        )}
      </Card.Body>
    </Card>
  );
}

function ChatDialogue(props: Props) {
  const [question, setQuestion] = React.useState<string>("");
  const { sendMessage } = useWebSocketContext();

  function onSendQuestion() {
    if (props.chat) {
      sendMessage({
        message_type: "chat_kb_question",
        session_uuid: props.chat.session_id,
        question: question,
      });
    }
    props.setChatQuestion(question);
    setQuestion("");
  }

  let messages = [];
  let waiting = false;
  if (props.chat) {
    for (let message of props.chat.messages) {
      if (message.question) {
        messages.push({
          author: "You",
          message: message.question,
          user: true,
          loading: false,
        });
        if (message.answer) {
          messages.push({
            author: "Next Fulcrum",
            message: message.answer.answer,
            user: false,
            loading: false,
          });
        } else {
          messages.push({
            author: "Next Fulcrum",
            message: "",
            user: false,
            loading: true,
          });
          waiting = true;
        }
      }
    }
  }

  return (
    <Card>
      <Card.Header>Chat</Card.Header>
      <ListGroup
        variant="flush"
        style={{ height: "80vh" }}
        className="d-flex justify-content-center flex-column"
      >
        <ListGroup.Item className="flex-fill" style={{ overflowY: "scroll" }}>
          {messages.map((props, idx) => (
            <ChatMessage key={idx} {...props} />
          ))}
        </ListGroup.Item>
        <ListGroup.Item>
          <Form>
            <Form.Group className="mb-3">
              <Form.Control
                as="textarea"
                placeholder="Enter message"
                rows={3}
                style={{ resize: "none" }}
                value={question}
                onChange={(e) => {
                  e.preventDefault();
                  setQuestion(e.target.value);
                }}
                disabled={props.chat === undefined || waiting}
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              disabled={question.length === 0 || waiting}
              onClick={(e) => {
                e.preventDefault();
                onSendQuestion();
              }}
            >
              Send
            </Button>
          </Form>
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
}

export default ChatDialogue;
