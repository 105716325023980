import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ChatList from "./ChatList";
import ChatResult from "./ChatResult";
import ChatDialogue from "./ChatDialogue";
import { WebSocketProvider, Message } from "../WebsocketProvider";

import { v4 as uuidv4 } from "uuid";

export interface IQuestionAnswer {
  question?: string | null;
  answer?: IAnswer;
}

export interface IChat {
  session_id: string;
  messages: IQuestionAnswer[];
}

export interface IAnswer {
  answer: string;
  evidence: string[];
}

interface ChatKbMessage extends Message {
  session_uuid: string;
}

interface ChatKbRequest extends ChatKbMessage {
  message_type: "chat_kb_question";
}

export interface ChatKbQuestionRequest extends ChatKbRequest {
  question: string;
}

interface ChatKbResponse extends ChatKbMessage {
  message_type: "chat_kb_answer";
}

export interface ChatKbAnswerResponse extends ChatKbResponse {
  answer: string;
  evidence: string[];
}

function Chat() {
  let [chats, setChats] = useState<IChat[]>([]);
  let [selected, setSelected] = useState<string | null>(null);
  let chat = chats.find((chat) => chat.session_id === selected);

  let callback = (data: ChatKbAnswerResponse) => {
    setChats((old) => {
      return old.map((chat) => {
        if (chat.session_id === selected) {
          let lastMessage = chat.messages[chat.messages.length - 1];
          lastMessage.answer = data;
          return {
            ...chat,
            messages: [
              ...chat.messages.slice(0, chat.messages.length - 1),
              lastMessage,
            ],
          };
        } else {
          return chat;
        }
      });
    });
  };

  function onNewChat() {
    const uuid = uuidv4();
    setChats((old) => {
      return [{ session_id: uuid, messages: [] }, ...old];
    });
    setSelected(uuid);
  }

  function setChatQuestion(question: string) {
    if (selected) {
      setChats((old) => {
        return old.map((chat) => {
          if (chat.session_id === selected) {
            return { ...chat, messages: [...chat.messages, { question }] };
          } else {
            return chat;
          }
        });
      });
    }
  }

  return (
    <WebSocketProvider<ChatKbAnswerResponse>
      path="/api/v1/chat_kb/ws"
      callback={callback}
    >
      <Row className="mt-3">
        <Col md="2" className="mr-1">
          <ChatList
            chats={chats}
            onNewChat={onNewChat}
            setSelected={setSelected}
            selected={selected}
          />
        </Col>
        <Col md="6">
          <ChatResult
            answer={
              (chat?.messages.length || 0) > 0
                ? chat?.messages[chat?.messages.length - 1].answer
                : undefined
            }
          />
        </Col>
        <Col md="4" className="ml-1">
          <ChatDialogue chat={chat} setChatQuestion={setChatQuestion} />
        </Col>
      </Row>
    </WebSocketProvider>
  );
}

export default Chat;
