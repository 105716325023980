import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

export interface UserInfo {
  oidc_sub: string;
}

interface Props {
  userinfo: UserInfo;
}

function NavBar(props: Props) {
  return (
    <div data-bs-theme="dark">
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Link to="/" className="navbar-brand">
            <img
              alt="Brand Logo"
              src="/logo-light.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{" "}
            Next Fulcrum
          </Link>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link className="nav-link" to="/chat">
                Chat
              </Link>
            </Nav>
            <Navbar.Text>Welcome, {props.userinfo.oidc_sub}!</Navbar.Text>
            <Nav>
              <a className="nav-link" href="/api/v1/auth/sign_out">
                Sign Out
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavBar;
