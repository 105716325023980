import React, { useEffect, useState } from "react";
import NavBar, { UserInfo } from "./components/NavBar";
import Container from "react-bootstrap/Container";
import Chat from "./components/Chat";
import { Routes, Route } from "react-router-dom";

function Home() {
  return <></>;
}

function NoMatch() {
  return <h1>Not Found</h1>;
}

function Loading() {
  return (
    <div
      style={{ height: "100vh" }}
      className="w-100 d-flex justify-content-center align-items-center flex-column"
    >
      <h1>Next Fulcrum</h1>
      <img src="/logo-dark.png" alt="logo" width="200" height="200" />
    </div>
  );
}

async function fetchPrincipal() {
  let url = "/api/v1/users/me";

  let response;
  try {
    response = await fetch(url);
    if (response.status !== 200) {
      throw new Error(`API Error: ${await response.text()}`);
    }
    const data = await response.json();
    return data;
  } catch (e) {
    // TODO: Include redirect path so that user gets back to frontend
    window.location.href = url + "?login=true";
  }
}

interface IUserInfo {
  isLoading: boolean;
  isError: boolean;
  error?: any;
  data?: UserInfo;
}

function App() {
  const [info, setInfo] = useState<IUserInfo>({
    isLoading: true,
    isError: false,
  });

  useEffect(() => {
    fetchPrincipal()
      .then((data) => {
        setInfo({ isLoading: false, isError: false, data: data });
      })
      .catch((e) => {
        setInfo({
          isLoading: false,
          isError: true,
          error: e,
        });
      });
  }, []);

  if (info.isLoading || info.data === undefined) {
    return <Loading />;
  } else if (info.isError) {
    // TODO: Make prettier
    return <h1>Error: {`${info.error}`}</h1>;
  } else {
    return (
      <>
        <NavBar userinfo={info.data} />
        <Container fluid={true}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="chat" element={<Chat />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </Container>
      </>
    );
  }
}

export default App;
