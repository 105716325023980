import React from "react";
import { Button, Card, ListGroup } from "react-bootstrap";

import { IChat } from "./Chat";

interface Props {
  chats: IChat[];
  onNewChat: () => void;
  setSelected: (session_id: string) => void;
  selected: string | null;
}

const TEXT_LIMIT = 25;

function ChatList(props: Props) {
  return (
    <Card>
      <Card.Header>Chat History</Card.Header>
      <ListGroup variant="flush">
        {props.chats.map((chat: IChat) => {
          return (
            <ListGroup.Item
              key={chat.session_id}
              onClick={() => props.setSelected(chat.session_id)}
              active={chat.session_id === props.selected}
            >
              {chat.messages.length > 0 && chat.messages[0].question
                ? chat.messages[0].question.slice(0, TEXT_LIMIT) +
                  (chat.messages[0].question.length > TEXT_LIMIT ? "..." : "")
                : "Untitled"}
            </ListGroup.Item>
          );
        })}
      </ListGroup>
      <Card.Footer className="text-center">
        <Button onClick={props.onNewChat} variant="success" size="sm">
          New Chat
        </Button>
      </Card.Footer>
    </Card>
  );
}

export default ChatList;
