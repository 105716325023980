// Import necessary hooks and libraries
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  PropsWithChildren,
} from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";

// Create a context for chat messages
const WebSocketContext = createContext<ProviderProps>({
  canSendMessages: false,
  sendMessage: () => {},
});

interface ProviderProps {
  canSendMessages: boolean;
  sendMessage: <T extends Message>(content: T) => void;
}

interface Props<IResponse> {
  path: string;
  callback: (data: IResponse) => void;
}

export interface Message {
  message_type: string;
}

export const WebSocketProvider = <IResponse extends Message>(
  props: PropsWithChildren<Props<IResponse>>,
) => {
  let url;
  if (window.location.protocol === "https:") {
    url = "wss://" + window.location.host + props.path;
  } else {
    url = "ws://" + window.location.host + props.path;
  }
  // Initialize the WebSocket connection and retrieve necessary properties
  const {
    sendMessage: sM,
    lastMessage,
    readyState,
  } = useWebSocket(url, {
    shouldReconnect: () => true,
  });

  // Check if WebSocket connection is open and ready for sending messages
  const canSendMessages = readyState === ReadyState.OPEN;

  const { callback } = props;

  // Handle the incoming WebSocket messages
  useEffect(() => {
    if (lastMessage && lastMessage.data) {
      let data: IResponse = JSON.parse(lastMessage.data);
      // Update the local chat messages state based on the message type
      callback(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  // Define the sendMessage function to send messages through the WebSocket connection
  const sendMessage = useCallback(
    (content: Message) => {
      if (canSendMessages) sM(JSON.stringify(content));
    },
    [canSendMessages, sM],
  );

  return (
    <WebSocketContext.Provider value={{ canSendMessages, sendMessage }}>
      {props.children}
    </WebSocketContext.Provider>
  );
};

// Define a custom hook to access the chat messages context
export const useWebSocketContext = () => useContext(WebSocketContext);
